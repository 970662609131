import { Component, computed, inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputUiComponent } from '@my-toolbox-fe/ui-elements';

import { RouterOutlet } from '@angular/router';

import { NavbarSmartComponent } from '../navbar/navbar.ui-component';
import { UserService } from '@my-toolbox-fe/data-access-auth';
import { TabsUiComponent } from '@my-toolbox-fe/feat-faq';

@Component({
  selector: 'mt-my-toolbox',
  standalone: true,
  imports: [
    CommonModule,
    InputUiComponent,
    TabsUiComponent,
    RouterOutlet,
    NavbarSmartComponent,
  ],
  templateUrl: './my-toolbox.ui-component.html',
  styleUrl: './my-toolbox.ui-component.scss',
})
export class MyToolboxUiComponent implements OnInit {
  private userService = inject(UserService);
  user = computed(() => {
    return this.userService.currentUserSig();
  });

  async ngOnInit() {
    await this.userService.getUser();
  }
}

import {
  SlColorPicker
} from "./chunk.6UIVKIPN.js";

// src/components/color-picker/color-picker.ts
var color_picker_default = SlColorPicker;
SlColorPicker.define("sl-color-picker");

export {
  color_picker_default
};

import { Component, Inject, inject, OnDestroy, OnInit, signal } from '@angular/core';
import { CommonModule, DOCUMENT, NgOptimizedImage } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ButtonUiComponent, LoaderUiComponent } from '@my-toolbox-fe/ui-elements';
import { Router } from '@angular/router';
import {
  MSAL_GUARD_CONFIG,
  MsalBroadcastService,
  MsalGuardConfiguration,
  MsalService,
} from '@azure/msal-angular';
import {
  AuthenticationResult,
  EventMessage,
  EventType,
  RedirectRequest,
} from '@azure/msal-browser';
import { filter } from 'rxjs';

@Component({
  selector: 'mt-login',
  standalone: true,
  imports: [CommonModule, FormsModule, ButtonUiComponent, NgOptimizedImage, LoaderUiComponent],
  templateUrl: './login.smart-component.html',
  styleUrl: './login.smart-component.scss',
})
export class LoginSmartComponent implements OnDestroy, OnInit {
  isLoading = signal(false);

  private router = inject(Router);

  private msalBroadcastService = inject(MsalBroadcastService);

  authStatus: string | null = '';

  searchParams = new URLSearchParams(new URL(window.location.href).search);

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,

    @Inject(DOCUMENT) private document: Document,
  ) {}

  ngOnInit(): void {
    this.authStatus = sessionStorage.getItem('auth-status');

    const redirect = this.searchParams.get('redirect') === 'true';

    if (redirect) {
      this.onSubmit();
    }

    this.msalBroadcastService.msalSubject$
      .pipe(filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS))
      .subscribe((result: EventMessage) => {
        this.router.navigateByUrl('/');
      });
  }

  onSubmit() {
    this.isLoading.set(true);
    if (this.msalGuardConfig.authRequest) {
      this.authService.loginRedirect({
        ...this.msalGuardConfig.authRequest,
      } as RedirectRequest);
    } else {
      this.authService.loginRedirect();
    }
  }

  ngOnDestroy(): void {
    this.isLoading.set(false);
  }
}

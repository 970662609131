import {
  SlAnimatedImage
} from "./chunk.MFAYXQHE.js";

// src/components/animated-image/animated-image.ts
var animated_image_default = SlAnimatedImage;
SlAnimatedImage.define("sl-animated-image");

export {
  animated_image_default
};

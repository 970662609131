import {
  SlSwitch
} from "./chunk.BJK3IM5Z.js";

// src/components/switch/switch.ts
var switch_default = SlSwitch;
SlSwitch.define("sl-switch");

export {
  switch_default
};

import {
  SlInclude
} from "./chunk.KMMHL7N2.js";

// src/components/include/include.ts
var include_default = SlInclude;
SlInclude.define("sl-include");

export {
  include_default
};

import { Route } from '@angular/router';
import { LoginSmartComponent } from '@my-toolbox-fe/feat-login';
import { MyToolboxUiComponent } from './ui-components/my-toolbox/my-toolbox.ui-component';
import { PageNotFoundUiComponent } from './ui-components/page-not-found/page-not-found.ui-component';
import { AuthGuard, LoginGuard, AdminGuard } from '@my-toolbox-fe/data-access-auth';
import { getPageTitle } from '@my-toolbox-fe/shared';

export const featShellRoutes: Route[] = [
  {
    path: '',
    component: MyToolboxUiComponent,
    canActivate: [AuthGuard],
    title: getPageTitle('Home'),
    children: [
      {
        path: '',
        loadChildren: () =>
          import('@my-toolbox-fe/feat-homepage').then((m) => m.featHomePageRoutes),
      },
      {
        path: 'search',
        title: getPageTitle('Search'),
        loadChildren: () =>
          import('@my-toolbox-fe/feat-search').then((mod) => mod.featSearchRoutes),
      },
      {
        path: 'upload',
        title: getPageTitle('Upload'),
        loadChildren: () =>
          import('@my-toolbox-fe/feat-upload').then((mod) => mod.featUploadRoutes),
      },
      {
        path: 'subscriptions',
        title: getPageTitle('Subscriptions'),
        loadChildren: () =>
          import('@my-toolbox-fe/feat-subscription').then((mod) => mod.featSubscriptionRoutes),
      },
      {
        path: 'asset',
        pathMatch: 'full',
        redirectTo: 'upload/history',
      },
      {
        path: 'asset',
        title: getPageTitle('Asset'),
        loadChildren: () => import('@my-toolbox-fe/feat-asset').then((mod) => mod.featAssetRoutes),
      },
      {
        path: 'admin',
        title: getPageTitle('admin'),
        canActivate: [AdminGuard],
        loadChildren: () => import('@my-toolbox-fe/feat-admin').then((mod) => mod.featAdminRoutes),
      },
    ],
  },
  {
    path: 'login',
    component: LoginSmartComponent,
    canActivate: [LoginGuard],
  },
  {
    path: '**',
    pathMatch: 'full',
    component: PageNotFoundUiComponent,
  },
];

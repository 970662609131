import {
  SlDrawer
} from "./chunk.3G3JQZ4T.js";

// src/components/drawer/drawer.ts
var drawer_default = SlDrawer;
SlDrawer.define("sl-drawer");

export {
  drawer_default
};

import {
  SlRating
} from "./chunk.DPE4HMZR.js";

// src/components/rating/rating.ts
var rating_default = SlRating;
SlRating.define("sl-rating");

export {
  rating_default
};

@if(user()) {
<header>
  <mt-navbar></mt-navbar>
</header>

<router-outlet></router-outlet>

<aside>
  <mt-tabs />
</aside>
}

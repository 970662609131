import { inject, Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class LogoutService {
  private authService = inject(MsalService);
  private router = inject(Router);

  logout() {
    const instance = this.authService.instance;
    const account = instance.getActiveAccount();
    instance.logoutRedirect({ account: account });
  }
}

import {
  SlFormatDate
} from "./chunk.K6FVXEO2.js";

// src/components/format-date/format-date.ts
var format_date_default = SlFormatDate;
SlFormatDate.define("sl-format-date");

export {
  format_date_default
};

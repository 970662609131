import {
  SlBreadcrumbItem
} from "./chunk.XJRALKVM.js";

// src/components/breadcrumb-item/breadcrumb-item.ts
var breadcrumb_item_default = SlBreadcrumbItem;
SlBreadcrumbItem.define("sl-breadcrumb-item");

export {
  breadcrumb_item_default
};

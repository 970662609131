import {
  SlTreeItem
} from "./chunk.CMCE2D3S.js";

// src/components/tree-item/tree-item.ts
var tree_item_default = SlTreeItem;
SlTreeItem.define("sl-tree-item");

export {
  tree_item_default
};

import {
  SlBadge
} from "./chunk.KJ4WE367.js";

// src/components/badge/badge.ts
var badge_default = SlBadge;
SlBadge.define("sl-badge");

export {
  badge_default
};

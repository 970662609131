import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandlerFn,
  HttpInterceptorFn,
  HttpRequest,
} from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';
import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { environment } from '@my-toolbox-fe/environments/environment';

function getErrorMessage(error: HttpErrorResponse) {
  switch (error.status) {
    case 401:
      return 'Unauthorized';
    case 403:
      return 'Forbidden';
    default:
      return `Unknown error: ${error.message}`;
  }
}

export const unauthorizedException: HttpInterceptorFn = (
  req: HttpRequest<unknown>,
  next: HttpHandlerFn,
): Observable<HttpEvent<unknown>> => {
  const router = inject(Router);
  const msalService = inject(MsalService);

  sessionStorage.removeItem('auth-status');

  return next(req).pipe(
    catchError((error: HttpErrorResponse) => {
      if (error.status === 401 || error.status === 403) {
        if (error.url?.includes(environment.baseUrl)) {
          sessionStorage.setItem('auth-status', `${error.status}`);
        } else {
          sessionStorage.removeItem('auth-status');
        }

        msalService.handleRedirectObservable().subscribe(console.warn);
        const account = msalService.instance.getActiveAccount();
        if (account) {
          msalService.instance.logoutRedirect({ account: account }).then(async () => {
            sessionStorage.removeItem('auth-status');

            await router.navigateByUrl('/login');
          });
        }

        // Properly use throwError
        return throwError(() => new Error(getErrorMessage(error)));
      }

      // Properly use throwError
      return throwError(() => error);
    }),
  );
};

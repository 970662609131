import {
  SlSkeleton
} from "./chunk.XNGAPZAP.js";

// src/components/skeleton/skeleton.ts
var skeleton_default = SlSkeleton;
SlSkeleton.define("sl-skeleton");

export {
  skeleton_default
};

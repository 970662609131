import { Injectable, OnDestroy } from '@angular/core';
import { environment } from '@my-toolbox-fe/environments/environment';
import { Subject } from 'rxjs';

const setInactivityTime = (minutes: number) => {
  return 1000 * 60 * minutes;
};

export const INACTIVITY_TIMEOUT = setInactivityTime(60);

@Injectable({
  providedIn: 'root',
})
export class InactivityService implements OnDestroy {
  userInactive$: Subject<void> = new Subject();
  private userActivity: string | number | NodeJS.Timeout | undefined;

  constructor() {
    this.setTimeout();
  }

  setTimeout() {
    if (environment.inactivityTimeout > 1440 || environment.inactivityTimeout <= 0) {
      this.userActivity = setTimeout(() => this.userInactive$.next(), INACTIVITY_TIMEOUT);
    } else {
      this.userActivity = setTimeout(
        () => this.userInactive$.next(),
        setInactivityTime(environment.inactivityTimeout),
      );
    }
  }

  refreshUserState() {
    clearTimeout(this.userActivity);
    this.setTimeout();
  }

  ngOnDestroy() {
    clearTimeout(this.userActivity);
  }
}

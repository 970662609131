<nav>
  <ul>
    <li>
      <a
        [routerLinkActiveOptions]="{ exact: true }"
        class="logo"
        routerLink="/"
        routerLinkActive="active"
        ><img alt="My toolbox logo" src="./assets/logo/myToolbox.svg"
      /></a>
    </li>
    @if (!environment.production) {
      <li class="environment">{{ environment.environment }}</li>
    }
    <li>
      <a [routerLinkActiveOptions]="{ exact: true }" routerLink="/search" routerLinkActive="active"
        >Search</a
      >
    </li>
    <li>
      <a
        [routerLinkActiveOptions]="{ exact: true }"
        routerLink="/subscriptions"
        routerLinkActive="active"
        >Subscriptions</a
      >
    </li>
    <li *roles="uploadRoles">
      <a [routerLinkActiveOptions]="{ exact: true }" routerLink="/upload" routerLinkActive="active"
        >Upload</a
      >
    </li>
    <li *roles="uploadRoles">
      <a
        [routerLinkActiveOptions]="{ exact: true }"
        routerLink="/upload/history"
        routerLinkActive="active"
        >Upload history</a
      >
    </li>
    <li *roles="adminRoles">
      <a [routerLinkActiveOptions]="{ exact: true }" routerLink="/admin" routerLinkActive="active"
        >PIM import</a
      >
    </li>
  </ul>
  <mt-profile></mt-profile>
</nav>

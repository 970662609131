import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { ProfileSmartComponent } from '../../smart-components/profile/profile.smart-component';
import {
  ROLE_MYTOOLBOX_ADMINISTRATOR,
  ROLE_UPLOADER_GLOBAL,
  ROLE_UPLOADER_LOCAL,
  RolesDirective,
} from '@my-toolbox-fe/feat-roles';
import { environment } from '@my-toolbox-fe/environments/environment';

@Component({
  selector: 'mt-navbar',
  standalone: true,
  imports: [CommonModule, RouterLink, RouterLinkActive, ProfileSmartComponent, RolesDirective],
  templateUrl: './navbar.ui-component.html',
  styleUrl: './navbar.ui-component.scss',
})
export class NavbarSmartComponent {
  uploadRoles = [ROLE_UPLOADER_GLOBAL, ROLE_UPLOADER_LOCAL];

  adminRoles = [ROLE_MYTOOLBOX_ADMINISTRATOR];
  protected readonly environment = environment;
}

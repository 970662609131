import {
  SlRange
} from "./chunk.MMIBHC74.js";

// src/components/range/range.ts
var range_default = SlRange;
SlRange.define("sl-range");

export {
  range_default
};

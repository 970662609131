import {
  SlButtonGroup
} from "./chunk.AKN2SAUH.js";

// src/components/button-group/button-group.ts
var button_group_default = SlButtonGroup;
SlButtonGroup.define("sl-button-group");

export {
  button_group_default
};

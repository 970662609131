import { Component, computed, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserService } from '@my-toolbox-fe/data-access-auth';
import { LogoutService } from '@my-toolbox-fe/feat-login';
import { ButtonUiComponent } from '@my-toolbox-fe/ui-elements';

@Component({
  selector: 'mt-profile',
  standalone: true,
  imports: [CommonModule, ButtonUiComponent],
  templateUrl: './profile.smart-component.html',
  styleUrl: './profile.smart-component.scss',
})
export class ProfileSmartComponent {
  private userService = inject(UserService);
  currentUser = computed(() => {
    return this.userService.currentUserSig();
  });
  private logoutService = inject(LogoutService);

  logout() {
    this.logoutService.logout();
  }
}

import {
  SlTree
} from "./chunk.HWE2L2VL.js";

// src/components/tree/tree.ts
var tree_default = SlTree;
SlTree.define("sl-tree");

export {
  tree_default
};
